.customer-list-table {
  border: 1px solid #f0f0f0;
}

.customer-list-header {
  margin: 0px;
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.status {
  border: 1px solid;
  border-radius: 32px;
  padding: 3px 8px;
  width: min-content;
  font-weight: 500;
  text-transform: capitalize;
}

.status--active {
  color: #0bad61;
  border-color: rgba(12, 194, 109, 0.16);
  background-color: rgba(12, 194, 109, 0.06);
}

.status--denied {
  color: #f34141;
  border-color: rgba(243, 65, 65, 0.16);
  background-color: rgba(243, 65, 65, 0.06);
}

.status--requested {
  color: #f9a52b;
  border-color: rgba(249, 165, 43, 0.16);
  background-color: rgba(249, 165, 43, 0.06);
}

.ant-table-cell {
  padding: 12px 16px !important;
}

.customer-list-filters {
  display: flex;
  gap: 16px;
  text-align: left;
  padding: 32px 0px;
}
