.customer-details-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 24px;
}

.block-button {
  display: flex;
  align-items: center;
}

.customer-details-avatar,
.customer-details-initials {
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.customer-details-user-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.customer-details-initials {
  background: purple;
  color: white;
  font-size: 22px;
  font-weight: bold;
  line-height: 48px;
}
.customer-details-user-name {
  color: #1f2633;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.customer-details-dob {
  color: #454d5f;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  text-align: left;
}

.customer-details-data-wrapper {
  display: flex;
  gap: 16px;
}

.customer-details-data {
  border: 1px solid #e8eaed;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 160px;
  height: 48px;
  overflow: hidden;
  flex-wrap: wrap;
}
.customer-details-data-value-large {
  color: #1f2633;
  font-size: 18px;
  line-height: 20px;
}

.customer-details-data-title {
  color: #6c7689;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.customer-details-key-value {
  color: #1f2633;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.customer-details-key-value {
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}

.customer-details-statuses {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
}

.customer-details-status {
  display: flex;
  gap: 16px;
  align-items: center;
}

.customer-details-status-title {
  color: #454d5f;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.customer-details-status-value {
  border: 1px solid;
  height: 28px;
  line-height: 28px;
  padding: 0px 8px;
}

.customer-details-status-value-passed {
  color: #0bad61;
  border-color: rgba(12, 194, 109, 0.16);
  background-color: rgba(12, 194, 109, 0.06);
}

.customer-details-status-value-failed {
  color: #f34141;
  border-color: rgba(243, 65, 65, 0.16);
  background-color: rgba(243, 65, 65, 0.06);
}
