.app__wrapper {
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
}

.app__contents {
  position: relative;
  top: 48px;
  height: calc(100vh - 48px);
  overflow-y: auto;
  padding: 40px;
  min-width: 1000px;
}
