.header {
  background-color: #fff;
  border-bottom: 1px solid #e8eaed;
  height: 48px;
  line-height: 48px;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  justify-content: space-between;
  font-size: 12px;
}

.header__logo {
  font-weight: 700;
  text-decoration: none;
  color: #000;
}

.header__logo img {
  padding-right: 6px;
}

.header__tab {
  color: #5a6272;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
}

.header__tab:hover {
  color: #000;
}

.header__tab--active {
  color: #000;
  font-weight: 700;
}

.logout__button {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  padding: 0px 10px;
  color: #f34141;
  background: rgba(255, 72, 72, 0.08);
  border: none;
  outline: none;
}

.flex {
  display: flex;
  align-items: center;
}

.header__logout {
  padding-right: 20px;
}
